import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { makeStyles, fade } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';

import Button from 'src/components/atoms/button';
import Input from 'src/components/atoms/input-new';
import { login } from 'src/redux/auth/actions';
import Grid from '@material-ui/core/Grid';

import './_index.scss';
import { toggleModal } from '../../../redux/app/actions';
import ModalForgotPassword from '../section-modal-forgot-password';

const styles = makeStyles({
  rootInput: {
    width: '100%',
  },
  customInput: {
    marginTop: 5,
    fontSize: '1.125rem',
    padding: '5px 15px',
    lineHeight: '1.5',
    borderRadius: '.3rem',
    position: 'relative',
    background: 'none',
    backgroundColor: '#fff',
    border: '1px solid #ced4da',
    width: '100%',
    height: 25,
    '&:focus': {
      backgroundColor: '#fff',
      boxShadow: `${fade('#153d77', 0.25)} 0 0 0 2px`,
    },
  },
  checkbox: {
    color: '#3b7ddd',
    fontSize: 11,
  },
  formControl: {
    width: '100%',
    marginTop: 20,
  },
  iconButton: {
    '&:focus': {
      outline: 'none',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  rootPaper: {
    padding: '0px 30px',
  },
});

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  password: Yup.string()
    .min(6, 'Must be longer than 6 characters')
    .max(32, 'Must be 32 characters or less')
    .required('Password is required'),
});

const SigninForm: React.FunctionComponent = () => {
  // CORE HOOKS
  const [loading, setLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [remember, setRemember] = useState(false);
  // OTHER NECESITIES
  const dispatch = useDispatch();
  const classes = styles();
  const callback = (success: boolean) => {
    setLoading(false);
    if (success) {
      window.location.replace('/payroll/transfer-payroll');
    }
  };
  const handleLogin = ({ email, password }) => {
    setLoading(true);
    dispatch(login({
      email,
      password,
    }, callback));
  };
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      handleLogin(values);
    },
  });

  const onReset = () => {
    dispatch(toggleModal({
      open: false,
    }));
  };

  const handleForgotPass = () => {
    dispatch(
      toggleModal({
        open: true,
        component: (
          <ModalForgotPassword
            onClose={onReset}
          />
        ),
      }),
    );
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container>
        <Grid item md={12} className="mb-25">
          <Input
            id="email"
            type="text"
            label="Your email"
            name="email"
            variant="standard"
            value={formik.values.email}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            error={formik.errors.email && formik.touched.email}
            errorMessage={formik.errors.email}
          />
        </Grid>
        <Grid item md={12} className="mb-25">
          <Input
            label="Password"
            name="password"
            variant="standard"
            type={showPass ? 'text' : 'password'}
            value={formik.values.password}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            id="password"
            showPass
            classVisible={{ root: classes.iconButton }}
            togglePass={() => setShowPass(!showPass)}
            error={formik.errors.password && formik.touched.password}
            errorMessage={formik.errors.password}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="flex-end"
      >
        <Grid item>
          <Button
            id="buttonLogin"
            disabled={loading}
            loading={loading}
            type="submit"
          >
            <Typography className="label-button">Login</Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="baseline"
      >

        <Grid item>
          <FormControlLabel
            control={(
              <Checkbox
                checked={remember}
                onChange={() => setRemember(!remember)}
                name="rememberMe"
                classes={{ root: classes.checkbox }}
                color="primary"
                size="small"
              />
            )}
            label={<span className="f--size__12">Remember me</span>}
          />
        </Grid>
        <Grid item>
          <InputLabel
            className="f--size__10 c-red"
            onClick={handleForgotPass}
            classes={{ root: classes.pointer }}
          >
            <b>Forgot Password</b>
          </InputLabel>
        </Grid>
      </Grid>
    </form>
  );
};

export default SigninForm;
