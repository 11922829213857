// PAYROLL_STAGES
export const INITIAL = -2;
export const UPLOADED = -1;
export const VALIDATED = 0;
export const IN_PROGRESS = 1;
export const CSV_SUCCESS = 2;
export const VALIDATION_FAILED = 3;
export const APPROVED = 4;
export const REJECTED = 5;
export const CSV_FAILED = 6;
export const UPLOAD_FAILED = 7;
export const SUBMIT = 8;
export const DELETED = 9;
export const PARTIAL_COMPLETED = 10;

// STATUS USER
export const ACTIVE = 1;
export const INACTIVE = 2;
export const INVITED = 6;

// USER ROLE
export const REQUESTER = 'payroll.merchant.requester';
export const APPROVER = 'payroll.merchant.approver';
export const ADMIN = 'payroll.merchant.admin';
export const SUPER_ADMIN = 'payroll.superAdmin';

// CONVERT ROLE
export const requester = 'Requester';
export const approver = 'Approver';
export const admin = 'Admin';
export const superAdmin = 'Super Admin';
