import { doLogin, getBrandLogo, getPermission } from 'src/services/auth';
import { ILoginPayload } from 'src/types/auth/http';
import { IUserLoginAction, IUserRoleAction } from 'src/types/auth/state';
import { IState } from 'src/types/state';
import jwtDecode from 'src/utils/jwt';
import { saveToken } from 'src/utils/local-storage';
import { convertRoleArrayToRoleTree } from 'src/utils/roles-data-converter';
import { openSnackbar } from '../app/actions';
import * as types from './constants';

export interface IAction<T> {
  type: string;
  payload: T;
}

export const userLogin = ({ token }): IAction<IUserLoginAction> => {
  const decoded = jwtDecode(token);
  return {
    type: types.LOGIN_SUCCESS,
    payload: {
      name: decoded.username,
      uid: decoded.sub,
    },
  };
};

export const mapUserRole = ({
  type,
  role = null,
  roleName = '',
  treeRole = null,
  loading,
  name = '',
  logo = '',
}): IAction<IUserRoleAction> => ({
  type,
  payload: {
    role,
    roleName,
    treeRole,
    loading,
    name,
    logo,
  },
});

export const login = (
  { email, password }: ILoginPayload, callback?,
) => async (
  dispatch,
) => {
  try {
    const response = await doLogin(email, password);
    // APPEND DATA TO LOCAL STORAGE
    saveToken(response.data.IDToken);
    // LOGIN USER TOKEN
    dispatch(userLogin({
      token: response.data.IDToken,
    }));
    if (callback) {
      callback(true, response.message);
    }
    dispatch(openSnackbar({
      type: 'success',
      message: response.message,
    }));
  } catch (error) {
    const errorObject = JSON.parse(error.message);
    dispatch({
      type: types.LOGIN_FAILED,
    });
    if (callback) {
      callback(false, errorObject.message);
    }
    dispatch(openSnackbar({
      type: 'error',
      message: errorObject.message,
    }));
  }
};

export const getRole = () => async (dispatch, getState) => {
  try {
    dispatch(
      mapUserRole({
        type: types.GET_USER_ROLE,
        loading: true,
      }),
    );
    const state: IState = getState();
    const response = await getPermission(state.auth.userData.id);
    let responseLogo = {
      data: '',
    };
    try {
      responseLogo = await getBrandLogo();
      // eslint-disable-next-line no-empty
    } catch (e) {
    }

    // CREATE NEW ROLE
    const role = response.permission_list;
    const newRole = role.concat(['/']);
    const treeRole = convertRoleArrayToRoleTree([...newRole]);
    dispatch(
      mapUserRole({
        type: types.MAP_USER_ROLE,
        role: newRole,
        roleName: response.role,
        name: response.name,
        treeRole,
        loading: false,
        logo: responseLogo.data,
      }),
    );
  } catch (error) {
    dispatch(
      mapUserRole({
        type: types.GET_USER_ROLE,
        loading: false,
      }),
    );
  }
};

export const logout = () => {};
